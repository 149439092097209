<template>
    <div class="network-examine">
        <div class="summery">
            <p>מוביל שוק: </p>
            <p>מבצע מוביל: </p>
        </div>
        <div class="networks">
            <template v-for="network in computed_networks" :key="network.name">
                <div class="network-row">
                    <div class="network-icon">
                        <img :src="network.icon">
                    </div>
                    <div class="network-data">
                        <p>מחיר ממוצע: </p>
                        <p>נתח שוק במוצר: </p>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { watch } from '@vue/runtime-core';
export default {
props:['sellers', 'product'],
setup(props){
    const networks = [
        {
            name: 'שופרסל',
            date: 2020,
            total_income: 7420000000,
            shops: 298,
            cost_plus: 26.5,
            icon: 'https://www.pc.co.il/wp-content/uploads/2017/04/c4cf7111baef96af156296bf959e81d5600.jpg'
        },
        {
            name: 'רמי לוי',
            date: 2020,
            total_income: 3152000000,
            shops: 52,
            cost_plus: 25.5,
            icon: 'http://r-fcenter.co.il/wp-content/uploads/2020/02/%D7%A8%D7%9E%D7%99-%D7%9C%D7%95%D7%99-%D7%9C%D7%95%D7%92%D7%95-1.jpg'
        },
        {
            name: 'יוחננוף',
            date: 2020,
            total_income: 1476000000,
            shops: 27,
            cost_plus: 26.6,
            icon: 'https://upload.wikimedia.org/wikipedia/he/d/d0/%D7%9C%D7%95%D7%92%D7%95_%D7%99%D7%95%D7%97%D7%A0%D7%A0%D7%95%D7%A3.png'
        },
        {
            name: 'ויקטורי',
            date: 2020,
            total_income: 1158000000,
            shops: 59,
            cost_plus: 25.4,
            icon: 'https://voice-over.co.il/wp-content/uploads/2019/10/dlogo_98e06f79-273e-42fe-892f-3b2f6ceb4750.png'
        },
        {
            name: 'פרש מרקט',
            date: 2020,
            total_income: 795000000,
            shops: 38,
            cost_plus: 34.7,
            icon:'https://upload.wikimedia.org/wikipedia/he/thumb/f/f4/FreshMarketLogo.png/200px-FreshMarketLogo.png'
        },
        {
            name: 'טיב טעם',
            date: 2020,
            total_income: 773000000,
            shops: 41,
            cost_plus: 32.6,
            icon: 'https://www.aral-a.co.il/wp-content/uploads/2017/12/%D7%98%D7%99%D7%91-%D7%98%D7%A2%D7%9D.png'
        },
        {
            name: 'סופר פארם',
            date: 2020,
            total_income: 5000000000,
            shops: 257,
            cost_plus: 28.7,
            icon: 'https://service.tlvmall.com/Uploads//Stores/%D7%A1%D7%95%D7%A4%D7%A8%20%D7%A4%D7%90%D7%A8%D7%9D/logo_super-pharm.png'
        },
        {
            name: 'BE',
            date: 2020,
            total_income: 216000000,
            shops: 257,
            cost_plus: 28.2,
            icon: 'https://upload.wikimedia.org/wikipedia/he/c/c8/Be_shufersal.png'
        },
        {
            name: 'סטופמרקט',
            date: 2020,
            total_income: 750000000,
            shops: 257,
            cost_plus: 28.2,
            icon: 'https://www.dotandairy.co.il/wp-content/uploads/2020/06/%D7%A1%D7%98%D7%95%D7%A4-%D7%9E%D7%A8%D7%A7%D7%98-e1415187302991.png'
        },
    ]

    const computed_networks = ref([]);

    const compute_data = () => {
        console.log('Calculating Data!');
        computed_networks.value = [];
        for(let j =0; j< networks.length ; j++){
            let network = networks[j];
            for(let i =0; i< props.sellers.length ; i++){
                let seller = props.sellers[i];
                if(seller.name.includes(network.name)){
                    let inx = computed_networks.value.findIndex(net => {
                        return net.name == network.name
                    })
                    if(inx == -1){
                        computed_networks.value.push(network);
                    }
                }
            }
        }
    }

    watch(props, () => {
        compute_data();
    })

    return{
       computed_networks,
    }
}
}
</script>

<style scoped>
.network-examine{
    width: 100%;
    height: 100%;
    background: white;
    display: grid;
    gap: 3px;
    grid-template-areas: 
    "summery"
    "networks";
    grid-template-rows: 30% 70%;
    grid-auto-columns: 100%;
}
.networks{
    width: 100%;
    height: 100%;
    grid-area: networks;
    display: grid;
    grid-auto-rows: calc(calc(100% / 6) - 3px);
    grid-template-columns: 100%;
    overflow-y: auto;
}
.summery{
    grid-area: summery;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.summery p{
    font-size: 18px;
}
.network-row{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}
.network-icon{
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.network-icon img{
    max-width: 85%;
    max-height: 85%;
}
.network-data{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 5px;
    padding-top: 5px;
}
</style>