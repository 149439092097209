<template>
    <div class="networks-data">
        <NetworksExamine :sellers ="item.prices" :product ="item"/>
    </div>
    <div class="examine">
        <div class="product-info">
            <p style="font-size:22px; font-weight:500;">{{item.name}}</p>
            <p>{{item.barcode}}</p>
            <p v-if="item.my_price == ''" style="color:whitesmoke; font-size:16px; background-color:darkred; padding:0 5px 0 5px; border-radius: 3px;">מלא מחיר מכירה על מנת לראות את ההשוואה</p>
            <p v-if="item.my_price != ''" style="color:green; font-size:16px">המחיר שלי: {{item.my_price}} ש"ח</p>
        </div>
        <table id="results">
            <tr>
                <th>רשת</th>
                <th>כתובת</th>
                <th>מחיר</th>
                <th>מבצע</th>
                <th>תוקף</th>
                <th>השוואה</th>
            </tr>
            <template v-for="(seller,i) in item.prices" :key="i">
                <tr>
                    <td data-th="רשת">{{seller.name}}</td>
                    <td data-th="כתובת">{{seller.adress.replace(item.region, '').replace(',', '').replace(',', '')}}</td>
                    <td data-th="מחיר">{{seller.price}}</td>
                    <td data-th="מבצע">
                        {{seller.sale.price.split('(')[0].replace('ש"ח', '')}}
                    </td>
                    <td data-th="תוקף">{{seller.sale.to_date}}</td>
                    <td v-if="item.my_price" data-th="השוואה">
                        <template v-if="(((Number(seller.price) / item.my_price)-1) * 100).toFixed(1) < 0">
                            <p style="color:var(--bg-red);">{{(((Number(seller.price) / item.my_price)-1) * -100).toFixed(0)}}%-</p>
                        </template>
                        <template v-else>
                            <p style="color:green;">{{(((Number(seller.price) / item.my_price)-1) * 100).toFixed(0)}}%+</p>
                        </template>
                    </td>
                    <td data-th="השוואה" v-else>
                        חסר נתון
                    </td>
                </tr>
            </template>
        </table>
    </div>
</template>

<script>
import NetworksExamine from './NetworksExamine.vue';

export default {
props:['item'],
components:{NetworksExamine},
setup(props){
    return{

    }
}
}
</script>

<style scoped>
.examine{
    width: 100%;
    height: 70vh;
    overflow-y: auto;
}
.networks-data{
    position: absolute;
    top: 0;
    right: -18vw;
    width: 17vw;
    height: 70vh;
    background-color: white;
    z-index: 10;
    border-radius: 5px;
    overflow:hidden;
    padding: 5px;
}

#results {
  border-collapse: collapse;
  width: 100%;
}
#results td, #results th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
#results th{
    width: fit-content;
    position: sticky;
    top: 0; 
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.4);
}
#results tr{background-color: #f2f2f2;}
#results tr:hover {background-color: #ddd;}
#results th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04AA6D;
  color: white;
}
.product-info{
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
}
@media only screen and (max-width: 768px) {
    .product-info{
        height: 100px;
        text-align: center;
    }
    #results th{
        display: none;
    }
    #results td{
        display: flex;
        background-color: whitesmoke;
        align-items: center;
    }
    #results td:first-child{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    #results td:last-child{
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    #results td::before {
        content: attr(data-th) ": ";
        font-weight: bold;
        width: 25%;
        display: inline-block;
    }
    #results tr{
        padding: 2px;
        border-radius: 10px;
        overflow: hidden;
        background-color: unset;
    }
    #results {
        border-collapse:separate;
        border-spacing:0 8px;    
        border-radius: 10px;  
    }
}
</style>