<template>
    <div class="check-catalog">
        <div class="no-catalog" v-if="!is_catalog">
            <p style="font-size: 32px; margin-bottom: 15px; color:var(--bg-blue)">בואו נתחיל!</p>
            <p style="font-size: 18px;">טען קובץ אקסל עם העמודות הבאות: </p>
            <p>ברקוד | מחיר עלות | מחיר מכירה</p>
            <el-button type="success" icon="el-icon-paperclip" style="margin-top: 15px; width:190px;" @click="load_file"> העלה קובץ</el-button>
            <el-button type="danger" icon="el-icon-video-camera" style="margin-top: 15px; margin-left:0; width:190px;"> סרטון הסבר</el-button>
            <input type="file" id="file_input" style="display: none;" @change="test_file">
        </div>

        <div class="catalog-table" v-if="is_catalog">
            <div class="toolbar">
                <div class="toolbar-btn">
                    <el-button type="warning" icon="el-icon-video-camera" style="height:100%;" @click="load_prices"> טען מחירים</el-button>
                </div>
            </div>
            <div class="table">
                <table id="items">
                    <tr>
                        <th>סטטוס</th>
                        <th>ברקוד</th>
                        <th>שם</th>
                        <th>עלות שלי</th>
                        <th>מכירה שלי</th>
                        <th>% רווח</th>
                        <th>זול</th>
                        <th>יקר</th>
                        <th>פירוט</th>
                        <th>מחיקה</th>
                    </tr>
                    <template v-for="item in catalog_items" :key="item.barcode">
                        <tr>
                            <td>{{item.item.status}}</td>
                            <td>{{item.item.barcode}}</td>
                            <td>{{item.item.name}}</td>
                            <td>{{(Number(item.item.cost)).toFixed(2)}}</td>
                            <td>{{item.item.sell}} ₪</td>
                            <td>{{(((item.item.sell / item.item.cost) - 1) * 100).toFixed(2)}}</td>
                            <td>{{item.item.low}}</td>
                            <td>{{item.item.heigh}}</td>
                            <td data-th="פירוט">  
                                <el-button type="primary" icon="el-icon-document" @click="" circle />
                            </td>
                            <td data-th="מחק">  
                                <el-button type="danger" icon="el-icon-delete" @click="" circle />
                            </td>                        
                        </tr>
                    </template>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { projectAuth, projectFirestore } from '../../firebase/config';
import XLSX from "xlsx";
import Swal from 'sweetalert2';

export default {
setup(){
    const is_catalog = ref(false);
    const catalog_items = ref([]);

    const get_catalog_items = async () =>{
        const data = await projectFirestore.collection('Apps').doc('Prices').collection('Users').doc(projectAuth.currentUser.uid).collection('Catalog').get()
        if(data.empty){
            is_catalog.value = false;
        }else{
            is_catalog.value = true;
            data.forEach(doc => {
                catalog_items.value.push(doc.data());
                catalog_items.value[catalog_items.value.length - 1].item.status = 'ממתין'
            })
        }
    }

    const load_file = () => {
        const btn = document.getElementById('file_input')
        if(btn){
            btn.click();
        }else{
            Swal.fire({
                icon: 'error',
                title: 'שגיאת מערכת',
                text: 'צור קשר עם תמכיה טכנית.'
            });
        }
    }

    const test_file = (e) =>{
        var file = e.target.files[0];
        if(!file){
            return
        }
        if(!file.name.includes('xlsx')){
            ElNotification({
                title: "שגיאה!",
                type: "error",
                message: "ניתן לטעון קובץ אקסל בלבד!",
                showClose: false
            });
            return
        }
        setTimeout(() => {
            proccess_xml(file);
        }, 1500);
    }

    const proccess_xml = (file) => {
        console.log('Processing!');
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = e => {
                const bufferArray = e.target.result;
                const workbook = XLSX.read(bufferArray, {
                    type: "buffer",
                    cellDates: true,
                    dateNF: "yyyy/mm/dd;@"
                });
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];
                const data = XLSX.utils.sheet_to_json(worksheet);
                resolve(data);
            };
            fileReader.onerror = error => {
            reject(error);
            };
        })
        promise.then(data => {
            check_data(data);
        })
    }

    const check_data = (data) => {
        for(let i = 0; i< data.length; i++){
            if(data.length > 1000){
                Swal.fire({
                    icon: 'error',
                    title: 'שגיאה',
                    text: 'משתמש חינמי מוגבל עד 50 פריטים'
                })
                return
            }
            let item = data[i];
            if(!item['ברקוד']){
                ElNotification({
                    title: "שגיאה!",
                    type: "error",
                    message: "בדוק את שדות קובץ האקסל, חסרה עמודת 'ברקוד' !!",
                    showClose: false
                });
                return
            }
        }
        console.log('File is - OK!');
        setTimeout(() => {
            console.log(data);
            update_db(data);
        }, 1500);
    }

    const update_db = async (data) => {
        for(let i = 0; i< data.length; i++){
            let row = data[i];
            if(row['ברקוד'] && row['ברקוד'].toString().length > 10){
                var item = {
                    barcode: row['ברקוד'],
                    cost: '',
                    sell: ''
                }
                if(row['מחיר מכירה']){
                    item.sell = row['מחיר מכירה']
                }
                if(row['מחיר עלות']){
                    item.cost = row['מחיר עלות']
                }
                await projectFirestore.collection('Apps').doc('Prices').collection('Users').doc(projectAuth.currentUser.uid).collection('Catalog').doc(row['ברקוד'].toString())
                .set({item})
            }
        }
        console.log('Done!');
    }

    const load_prices = async () => {
        for(let i =0; i < catalog_items.value.length ; i++){
            let item = catalog_items.value[i].item
            item.region = 'חיפה'
            await check_item(item);
        }
    }

    const check_item = async (item) => {
        const item_data = await fetch(`https://cyberpark-server.herokuapp.com/admin/fetchpricelist/${item.barcode}/${item.region}`);
        const type = item_data.headers.get("content-type");
        if(type && type.indexOf("application/json") !== -1){

            let prices = await item_data.json();

            prices.sort((itA, itB) => {
                if(Number(itA.price) < Number(itB.price)){return -1}
                if(Number(itA.price) > Number(itB.price)){return 1}
                return 0
            })

            if(prices.length > 1){
                item.name = prices[0].product;
                item.prices = prices;
                item.status = 'טופל';
                item.low = prices[0].price;
                item.heigh = prices[item.prices.length - 1].price;
            }else{
                item.status = "אין תוצאות";
                item.name = "אין תוצאות";
            }
        }else{
            item.status = "אין תוצאות"
            item.name = "אין תוצאות";
        }
    }

    onMounted(() => {
        get_catalog_items();
    })

    return{
        is_catalog, catalog_items, load_file, test_file,load_prices
    }
}
}
</script>

<style scoped>
.check-catalog{
    width: 100%;
    height: calc(100vh - 90px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.no-catalog{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.catalog-table{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-areas: 
    "toolbar"
    "table";
    grid-template-columns: 100%;
    grid-template-rows: 10% 90%;
}
.toolbar{
    grid-area: toolbar;
    width: 100%;
    height: 100%;
}
.toolbar-btn{
    padding: 10px;
    height: calc(100% - 20px);
    width: fit-content;
}
.table{
    grid-area: table;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
#items {
  border-collapse: collapse;
  width: 100%;
}
#items td, #items th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
#items th{
    width: fit-content;
    position: sticky;
    top: 0; 
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.4);
    z-index: 1020;
}
#items tr{background-color: #f2f2f2;}
#items tr:hover {background-color: #ddd;}
#items th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04AA6D;
  color: white;
}

</style>