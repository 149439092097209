<template>
    <el-dialog v-model="add_item_dialog" width="360px" @closed="clean_new_item">
        <div class="add-item" v-if="!loading_mini">
            <div class="add-item-title">
                <p>הוספת פריט לקבוצה</p>
            </div>
            <div class="input-barcode">
                <el-input placeholder="ברקוד מלא" v-model="new_item.barcode"></el-input>
                <el-select v-model="new_item.region" placeholder="בחר אזור" style="width:100%; margin-top: 15px;">
                    <el-option v-for="region in regions" :key="region" :label="region" :value="region"/>
                </el-select>
                <div class="found-item">
                    {{new_item.name}}
                </div>
                <el-button type="success" style="width:100%; margin-top: 15px;" @click="check_item(new_item.barcode, new_item.region)" v-if="!new_item.name"> בדוק ברקוד </el-button>
                <el-button type="success" style="width:100%; margin-top: 15px;" @click="add_item" v-if="new_item.name"> הוסף פריט </el-button>
            </div>
        </div>
        <div class="loading-mini" v-else>
            <i class="el-icon-loading" style="font-size: 102px; margin-bottom: 30px;"></i>
            <p style="text-align:center;">מחפש את המוצר, אנא המתן...</p>
        </div>
    </el-dialog>

    <el-dialog v-model="update_group_dialog" width="360px">
        <div class="add-item">
            <div class="add-item-title">
                <p>עדכן שם קבוצה</p>
            </div>
            <div class="input-barcode">
                <el-input placeholder="ברקוד מלא" v-model="groupes[index_to_edit].name"></el-input>
                <el-button type="success" style="width:100%; margin-top: 25px; height:60px;" @click="save_group" > שמור </el-button>
            </div>
        </div>
    </el-dialog>

    <el-dialog v-model="create_group_dialog" width="360px">
        <div class="add-item">
            <div class="add-item-title">
                <p>שם קבוצה</p>
            </div>
            <div class="input-barcode">
                <el-input placeholder="שם הקבוצה" v-model="new_group.name"></el-input>
                <el-button type="success" style="width:100%; margin-top: 25px; height:60px;" @click="save_new_group" > שמור </el-button>
            </div>
        </div>
    </el-dialog>

    <el-dialog v-model="examine_item_dialog" :width="computed_width">
        <ExamineItem :item='selected_item' v-if="selected_item"/>
    </el-dialog>

    <el-dialog v-model="add_item_weighted_dialog" :width="computed_width">
        <PresetItems :group="current_group" :groupitems="items" v-if="current_group"/>
    </el-dialog>
    
    <el-dialog v-model="check_list_dialog" :width="computed_width" :fullscreen="true">
        <CheckPriceList />
    </el-dialog>

    <el-dialog v-model="check_catalog" :width="computed_width" :fullscreen="true">
        <CheckCatalog />
    </el-dialog>

    <div class="loading" v-if="loading">
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/585d0331234507.564a1d239ac5e.gif" alt="" style="width:150px; height:auto;">
        <p>טוען נתונים, אנא המתן...</p>
    </div>
    <div class="prices" v-show="!loading">
        <div class="import-manager" v-if="importing_file">
            <p class="import_status">{{importing_msgs}}</p>
        </div>
        <div class="toolbar">
            <div class="tool" @click="create_group">
                <i class="el-icon-plus" style="margin-left: 15px;"></i>
                <p>הוסף קבוצה</p>
            </div>
            <div class="tool secondary" @click="check_list_dialog = !check_list_dialog">
                <i class="el-icon-document" style="margin-left: 15px;"></i>
                <p>בדוק רשימה</p>
            </div>
            <!-- <div class="tool secondary" @click="check_catalog = !check_catalog" v-if="user.profile.role == 'admin'">
                <i class="el-icon-reading" style="margin-left: 15px;"></i>
                <p>בדוק קטלוג</p>
            </div> -->
            <div class="tool secondary">
                <i class="el-icon-help" style="margin-left: 15px;"></i>
                <p>עזרה</p>
            </div>
        </div>

        <div class="groups">
            <template v-for="(group,i) in groupes" :key="group.id">
                <div class="group" @click="select_group(i)">
                    <div class="fetching-group" v-if="fetching_item">
                        <i class="el-icon-loading" style="font-size: 28px;"></i>
                    </div>
                    <div class="group-name">
                        <p>{{group.name}}</p>
                    </div>
                    <div class="group-actions">
                        <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="delete_group(i)"></el-button>
                        <el-button type="primary" icon="el-icon-edit" circle @click="update_group(i)" size="mini"></el-button>
                    </div>
                </div>
            </template>
        </div>

        <div class="main" v-if="current_group">
            <div class="group-tools">
                <el-button type="info" @click="add_item_dialog = !add_item_dialog" style="margin-left:15px">הוסף ברקוד</el-button>
                <el-button type="info" @click="add_item_weighted_dialog = !add_item_weighted_dialog" style="margin-left:15px">הוסף פריט שקיל</el-button>
                <el-button type="info" @click="import_items_from_excel" style="margin-left:15px" class="hide-mobile">טען מאקסל</el-button>
                <input type="file" id="import_file" style="display:none" accept=".xlsx" @change="load_imported_xlsx">
                <el-tooltip class="item" effect="light" content="ייצא את הקבוצה לאקסל" placement="bottom">
                    <div class="export-excel" @click="export_to_excel">
                        <img src="https://image.flaticon.com/icons/png/512/732/732220.png" alt="">
                    </div>
                </el-tooltip>
                <!-- <el-tooltip class="item" effect="light" content="הדפס" placement="bottom">
                    <div class="export-excel" @click="print_table_group">
                        <img src="https://image.flaticon.com/icons/png/512/732/732220.png" alt="">
                    </div>
                </el-tooltip> -->
                <el-select v-model="selected_competition" placeholder="בחר רשת" class="hide-mobile" filterable clearable  style="margin-left: 15px; width:120px;">
                    <el-option v-for="item in competition"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name">
                    </el-option>
                </el-select>
                <el-select v-model="selected_store" placeholder="בחר סניף" class="hide-mobile" filterable clearable  style="margin-left: 15px; width:120px;">
                    <el-option v-for="item in competition_stores"
                    :key="item"
                    :label="item"
                    :value="item">
                    </el-option>
                </el-select>
                <el-input placeholder="חיפוש חופשי" v-model="search_string" style="width:180px;" class="hide-mobile"></el-input>
            </div>
            <table id="items" v-if="!loading_items">
                <tr>
                    <th>ברקוד</th>
                    <th>שם</th>
                    <th>אזור בדיקה</th>
                    <th>מחיר מכירה</th>
                    <th>זול (קטלוגי)</th>
                    <th>יקר (קטלוגי)</th>
                    <th v-if="selected_competition">{{selected_competition}}</th>
                    <th v-if="selected_store">{{selected_store}}</th>
                    <th v-if="selected_store">מבצע</th>
                    <th>מס חנויות</th>
                    <th>פירוט</th>
                    <th>מחק</th>
                </tr>
                <template v-for="item in sorted_items" :key="item">
                    <template v-if="item.group_id == current_group.id">
                        <tr v-if="item.prices">
                            <td data-th="ברקוד">
                                <template  v-if="selected_store && item.my_price && !isNaN(store_price(item)) && Number(store_price(item)) < Number(item.my_price)">
                                    <p v-if="item.barcode && String(item.barcode).includes('משקל')" style="background-color:red; color:white;">ללא</p>
                                    <p v-else style="background-color:red; color:white;">{{item.barcode}}</p>
                                </template>
                                <template v-else>
                                    <p v-if="item.barcode && String(item.barcode).includes('משקל')">ללא</p>
                                    <p v-else>{{item.barcode}}</p>
                                </template>
                            </td>
                            <td data-th="שם">{{item.name}}</td>
                            <td data-th="אזור">{{item.region}}</td>
                            <td data-th="מחיר">
                                <div class="my-price">
                                    <el-tooltip class="item" effect="light" content="המחיר ישמש להשוואה" placement="bottom">
                                        <el-input placeholder="הוסף" v-model="item.my_price" style="width:85px;"></el-input>
                                    </el-tooltip>
                                    <el-button type="success" icon="el-icon-check" circle @click="update_price(item)" size="mini" style="margin-right:5px;"></el-button>  
                                </div>
                            </td>
                            <td data-th="זול">{{item.low}}</td>
                            <td data-th="יקר">{{item.heigh}}</td>
                            <td v-if="selected_competition" style="background-color:yellow;">{{competition_price(item)}}</td>
                            <td v-if="selected_store" style="background-color:pink;">{{store_price(item)}}</td>
                            <td v-if="selected_store" style="background-color:gold;">{{store_sale(item)}}</td>
                            <td>{{item.prices.length}}</td>
                            <td data-th="פירוט">  
                                <el-button type="primary" icon="el-icon-document" @click="examine_item(item)" circle />
                            </td>
                            <td data-th="מחק">  
                                <el-button type="danger" icon="el-icon-delete" @click="delete_item(item)" circle />
                            </td>
                        </tr>
                    </template>
                </template>
            </table>
            <div class="loading-items" v-if="loading_items">
                <img src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/585d0331234507.564a1d239ac5e.gif" alt="" style="width:150px; height:auto;">
                <p>טוען נתונים, אנא המתן...</p>
            </div>
        </div>

    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, onMounted } from '@vue/runtime-core';
import { projectAuth, projectFirestore } from '../../firebase/config';
import Swal from 'sweetalert2';
import store from '../../store';
import ExamineItem from './ExamineItem.vue';
import PresetItems from './PresetItems.vue';
import CheckPriceList from './CheckPriceList.vue';
import XLSX from "xlsx";
import CheckCatalog from './CheckCatalog.vue';
import { ElNotification } from "element-plus";

export default {
components:{ExamineItem, PresetItems, CheckPriceList, CheckCatalog},
setup(){
    const loading = ref(false);
    let total = 0;

    const user = ref(computed(() => {
        return store.getters.user
    }))
    const groupes = ref([]);
    const current_group = ref(null);
    const items = ref([]);
    const loading_items = ref(false);
    const add_item_dialog = ref(false);
    const add_item_weighted_dialog = ref(false);

    const search_string = ref('');
    const sorted_items = ref(computed(() => {
        var _items = items.value.sort((itA, itB) => {
            if(itA.name < itB.name){return -1}
            if(itA.name > itB.name){return 1}
            return 0
        })
        if(search_string.value.length < 1){
            return _items
        }else{
            return _items.filter(item => {
                return item.name.includes(search_string.value);
            })
        }
        
    }))

    const update_group_dialog = ref(false);
    const index_to_edit = ref(null);

    const create_group_dialog = ref(false);
    const check_list_dialog = ref(false);
    const check_catalog = ref(false);

    const importing_file = ref(false);
    const importing_msgs = ref('');

    const competition = ref([]);
    const selected_competition = ref(null);
    const selected_store = ref(null);
    const competition_stores = ref(computed(() =>{
        selected_store.value = null;
        if(selected_competition.value){
            let inx = competition.value.findIndex(net => {
                return net.name == selected_competition.value
            })
            if(inx != -1){
                return competition.value[inx].stores
            }
        }
    }))
    const fetching_item = ref(false);

    const competition_price = (item) => {
        let min = 1000;
        item.prices.forEach(store => {
            if(store.name == selected_competition.value){
                if(Number(store.price) < min){
                    min = Number(store.price)
                }
            }
        })
        if(min == 1000){
            min = 'לא מוכר'
        }
        return min
    }
    const store_price = (item) => {
        let min = 1000;
        item.prices.forEach(store => {
            if((store.adress.split(',')[0] == selected_store.value) && (selected_competition.value == store.name)){
                if(Number(store.price) < min){
                    min = Number(store.price)
                }
            }
        })
        if(min == 1000){
            min = 'לא מוכר'
        }
        return min
    }
    const store_sale = (item) => {
        for(let i = 0; i < item.prices.length ; i++){
            let store = item.prices[i];
            if((store.adress.split(',')[0] == selected_store.value) && (selected_competition.value == store.name)){
                if(store.sale && store.sale.price){
                    return store.sale.price
                }
            }
        }
        return ' '
    }

    const new_item = ref({
        group_id: '',
        barcode: '',
        region: '',
        my_price: ''
    })

    const new_group = ref({
        name: '',
        id: '',
        order: 0
    })

    const regions = [
        "תל אביב",
        "חיפה",
        "נתניה",
        "אשקלון",
        "רעננה",
        "באר שבע",
        "אילת"
    ]

    const get_groupes = async () => {
        groupes.value = [];
        const data = await projectFirestore.collection('Apps').doc('Prices').collection('Users').doc(projectAuth.currentUser.uid).collection('Groupes').get()
        data.forEach(async group =>{
            groupes.value.push(group.data());
            sort_groupes();
            get_items(group.id)
        })
        if(data.empty){
           const doc = projectFirestore.collection('Apps').doc('Prices').collection('Users').doc(projectAuth.currentUser.uid).collection('Groupes').doc()
           await doc.set({
               id: doc.id,
               name: 'קבוצת ניסיון',
               order: 1
           })
           groupes.value.push({
               id: doc.id,
               name: 'קבוצת ניסיון',
               order: 1
           })
           current_group.value = groupes.value[0]
        }
        current_group.value = groupes.value[0]
    }

    const check_item = async (barcode, region) => {
        loading_mini.value = true;
        const item_data = await fetch(`https://cyberpark-server.herokuapp.com/admin/fetchpricelist/${barcode}/${region}`)
        const type = item_data.headers.get("content-type");
        if(type && type.indexOf("application/json") !== -1){
            const item = await item_data.json()
            if(item.length > 1){
                new_item.value.name = item[0].product;
                loading_mini.value = false;
            }
        }else{
            loading_mini.value = false;

            new_item.value.name = '';
            new_item.value.barcode = '';
            new_item.value.region = '';
            add_item_dialog.value = false;
            Swal.fire({
                icon: 'error',
                title: 'שגיאה',
                text: 'פריט לא נמצא במאגר',
                confirmButtonColor: 'green'
            })
        }
    }

    const get_items = async (group_id) => {
        items.value = [];
        projectFirestore.collection('Apps').doc('Prices').collection('Users').doc(projectAuth.currentUser.uid).collection('Groupes').doc(group_id).collection('Items')
        .onSnapshot(snapshot => {
            snapshot.docChanges().forEach(async change => {
                if (change.type === "added") {
                    var final_item = await get_item_data(change.doc.data());
                    items.value.push(final_item);
                }
                if (change.type === "modified") {
                   
                }
                if (change.type === "removed") {
                    let i = items.value.findIndex(item => {
                        return item.barcode == change.doc.data().barcode
                    })
                    if(i != -1){
                        items.value.splice(i, 1);
                    }
                }
            })
        })
    }

    
    const get_item_data = async (item) => {
        fetching_item.value = true;
        total ++;

        const item_data = await fetch(`https://cyberpark-server.herokuapp.com/admin/fetchpricelist/${item.barcode}/${item.region}`)
        const json_data = await item_data.json()
        item.prices = json_data;
        item.prices = item.prices.sort((itA, itB) => {
            if(Number(itA.price) < Number(itB.price)){return -1}
            if(Number(itA.price) > Number(itB.price)){return 1}
            return 0
        })
        item.low = item.prices[0].price
        item.heigh = item.prices[item.prices.length - 1].price;
        item.prices.forEach(network => {
            let indx = competition.value.findIndex(net => {
                return net.name == network.name
            })
            if(indx == -1){
                competition.value.push({
                    name: network.name,
                    stores: [network.adress.split(',')[0]]
                })
            }else{
                if(!competition.value[indx].stores.includes(network.adress.split(',')[0])){
                    competition.value[indx].stores.push(network.adress.split(',')[0])
                }
            }
        })

        total --;
        if(total < 2){
            fetching_item.value = false;
        }
        return item
    }

    const sort_groupes = () => {
        groupes.value = groupes.value.sort((gr1,gr2) => {
            if(gr1.order > gr2.order){ return -1}
            if(gr1.order < gr2.order){return 1}
            return 0
        })
    }

    const loading_mini = ref(false);

    const add_item = async () => {
        if(items.value.length > 1000000 && user.value.role != 'client'){
            add_item_dialog.value = false;
            Swal.fire({
                icon: 'warning',
                title: 'אופס',
                text: 'משתמשים חינמיים יכולים להוסיף עד 10 פריטים להשוואה',
                confirmButtonColor: 'green'
            })
        }else{
            if(new_item.value.barcode && new_item.value.region){
                new_item.value.group_id = current_group.value.id;
                console.log(new_item.value);
                await projectFirestore.collection('Apps').doc('Prices').collection('Users').doc(projectAuth.currentUser.uid).collection('Groupes').doc(new_item.value.group_id).collection('Items')
                .doc(new_item.value.barcode+'_'+new_item.value.region).set(new_item.value)
                add_item_dialog.value = false;
                Swal.fire({
                    icon: 'success',
                    title: 'נוסף בהצלחה!',
                    text: 'אנו נאסוף מידע במשך מספר שניות ולאחר מכן הפריט יופיע ברשימה.',
                    confirmButtonColor: 'green'
                }).then(() => {
                    new_item.value.group_id = '';
                    new_item.value.barcode = '';
                    new_item.value.region = '';
                    new_item.value.my_price = '';
                })
            }
        }
    }

    const delete_item = async (item) =>{
        await projectFirestore.collection('Apps').doc('Prices').collection('Users').doc(projectAuth.currentUser.uid).collection('Groupes').doc(current_group.value.id).collection('Items')
        .doc(item.barcode+'_'+item.region).delete()
        Swal.fire({
            icon: 'success',
            title: 'הצלחה!',
            text: 'הפריט נמחק בהצלחה',
            confirmButtonColor: 'green'
        });
    }

    const update_group = (index) =>{
        index_to_edit.value = index;
        update_group_dialog.value = true;
    }

    const save_group = async () =>{
        update_group_dialog.value = false;
        await projectFirestore.collection('Apps').doc('Prices').collection('Users').doc(projectAuth.currentUser.uid).collection('Groupes').
        doc(groupes.value[index_to_edit.value].id).set(groupes.value[index_to_edit.value])
        Swal.fire({
            icon: 'success',
            title: 'עודכן בהצלחה!',
            confirmButtonColor: 'green'
        })
    }

    const update_price = async (item) => {
        await projectFirestore.collection('Apps').doc('Prices').collection('Users').doc(projectAuth.currentUser.uid).collection('Groupes').doc(current_group.value.id).collection('Items')
        .doc(item.barcode+'_'+item.region).update({
            my_price: item.my_price
        })
    }

    const create_group = () => {
        create_group_dialog.value = true;
    }

    const select_group = (index) =>{
        current_group.value = groupes.value[index];
        total = 0;
    }

    const delete_group = async (index) =>{
        let group_id = groupes.value[index].id;
        Swal.fire({
            icon: 'question',
            title: 'מחיקת קבוצה',
            text: 'האם אתה בטוח?',
            confirmButtonText: 'בטוח',
            denyButtonText: 'בטל',
            showDenyButton: true
        }).then(async dat =>{
            if(dat.isConfirmed){
                await projectFirestore.collection('Apps').doc('Prices').collection('Users').doc(projectAuth.currentUser.uid).collection('Groupes').doc(group_id)
                .delete()
                groupes.value.splice(index, 1);
                Swal.fire({
                    icon: 'success',
                    title: 'נמחק בהצלחה, מרענן את כלל הקבוצות.',
                    confirmButtonColor: 'green'
                }).then(() => {
                    if(groupes.value[0]){
                        current_group.value = groupes.value[0];
                    }else{
                        current_group.value = null;
                        get_groupes();
                    }
                })
            }else{

            }
        })
    }

    const save_new_group = async () =>{
        const doc = projectFirestore.collection('Apps').doc('Prices').collection('Users').doc(projectAuth.currentUser.uid).collection('Groupes').doc()
        new_group.value.id = doc.id;
        await doc.set(new_group.value)
        create_group_dialog.value = false;
        Swal.fire({
            icon: 'success',
            title: 'הצלחה!',
            text: 'הקבוצה נוצרה בהצלחה',
            confirmButtonColor: 'green'
        }).then(() => {
            get_groupes();
            new_group.value.name = '';
            new_group.value.id = '';
            new_group.value.order = '';
        })
    }

    const selected_item = ref(null);
    const examine_item_dialog = ref(false);

    const examine_item = (item) => {
        console.log(item);
        selected_item.value = item;
        examine_item_dialog.value = true;
    }

    const clean_new_item = () => {
        new_item.value.group_id = '';
        new_item.value.barcode = '';
        new_item.value.region = '';
        new_item.value.my_price = '';
        new_item.value.name = '';
    }

    const export_to_excel = () => {
        console.log('Exporting... xlsx');
        let data = [];
        items.value.forEach(item => {
            if(item.group_id == current_group.value.id){
                let barcode = '';
                if(String(item.barcode).includes('משקל')){
                    barcode = 'ללא'
                }else{
                    barcode = String(item.barcode)
                }
                let row = {
                    שם: item.name,
                    ברקוד: barcode,
                    'מחיר שלי': item.my_price,
                    אזור: item.region,
                    גבוהה: item.heigh,
                    נמוך: item.low
                }
                if(selected_competition.value){
                    row[selected_competition.value] = competition_price(item)
                }
                if(selected_store.value){
                    let _store_price = store_price(item);
                    row[selected_store.value] = _store_price;
                    row['מבצע סניף'] = store_sale(item);
                    if(!isNaN(_store_price) && item.my_price){
                        if(Number(_store_price) - Number(item.my_price) > 0){
                            row['החלטה'] = 'להעלות מחיר'
                        }else{
                            row['החלטה'] = 'לבדוק מחיר'
                        }
                    }
                }
                data.push(row);
            }
        })
        let workSheet = XLSX.utils.json_to_sheet(data);

        let workbook = XLSX.utils.book_new();
        workbook.Workbook = {};
        workbook.Workbook.Views = [];
        workbook.Workbook.Views[0] = {};
        workbook.Workbook.Views[0].RTL = true;
        XLSX.utils.book_append_sheet(workbook, workSheet, `דוח מחירים - ${current_group.value.name}`);
        XLSX.writeFile(workbook, `דוח מחירים - ${current_group.value.name}.xlsx`);
    }

    const print_table_group = () =>{
        const table = document.getElementById('items')
        if(table){
            let newWin = window.open("");
            newWin.document.write(`<html><head><title>השוואת מחירים - ${current_group.value.name}</title>`);
            newWin.document.write(table.outerHTML);
            newWin.print();
            //newWin.close();
        }
    }

    const computed_width = ref(computed(() => {
        if(window.innerWidth > 768){
            return "60%"
        }else{
            return "98%"
        }
    }));

    const import_items_from_excel = () => {
        Swal.fire({
            icon: 'info',
            title: 'שים לב',
            text: 'חייבת להופיע עמודה עם השם ברקוד בקובץ האקסל. ניתן להוסיף גם עמודת מחיר מכירה ו מחיר עלות.'
        }).then(() => {
            const input = document.getElementById('import_file');
            if(input){
                input.click();
            }
        })
    }

    const load_imported_xlsx = (e) => {
        var file = e.target.files[0];
        if(!file){
            return
        }
        if(!file.name.includes('xlsx')){
            ElNotification({
                title: "שגיאה!",
                type: "error",
                message: "ניתן לטעון קובץ אקסל בלבד!",
                showClose: false,
                duration: 1250
            });
            return
        }
        proccess_xml(file)
    }

    const proccess_xml = (file) => {
        importing_file.value = true;
        importing_msgs.value = 'טוען את הקובץ הנבחר'
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = e => {
                const bufferArray = e.target.result;
                const workbook = XLSX.read(bufferArray, {
                    type: "buffer",
                    cellDates: true,
                    dateNF: "yyyy/mm/dd;@"
                });
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];
                const data = XLSX.utils.sheet_to_json(worksheet);
                resolve(data);
            };
            fileReader.onerror = error => {
            reject(error);
            };
        })
        promise.then(data => {
            check_data(data);
        })
    }

    const check_data = (data) => {
        importing_msgs.value = 'בודק את תקינות הקובץ'
        for(let i = 0; i< data.length; i++){
            let item = data[i];
            if(!item['ברקוד']){
                ElNotification({
                    title: "שגיאה!",
                    type: "error",
                    message: "בדוק את שדות קובץ האקסל, חסרה עמודת 'ברקוד' !!",
                    showClose: false
                });
                importing_file.value = false;
                return
            }
        }
        console.log('File is - OK!');
        setTimeout(() => {
            add_items_from_data(data);
        }, 1500);
    }

    const add_items_from_data = async (data) => {
        importing_msgs.value = 'מתחיל להוסיף פריטים...נא לא לצאת מהחלון!'
        for(let i =0; i< data.length; i++){
            let item = data[i];
            if(item['ברקוד']){
                if(item['מחיר מכירה']){
                    new_item.value.my_price = item['מחיר מכירה']
                }
                if(item['מחיר עלות']){
                    new_item.value.cost = item['מחיר עלות']
                }
                new_item.value.region = 'חיפה';
                new_item.value.barcode = item['ברקוד'];
                new_item.value.group_id = current_group.value.id;
                const item_data = await fetch(`https://cyberpark-server.herokuapp.com/admin/fetchpricelist/${item['ברקוד']}/חיפה`)
                const type = item_data.headers.get("content-type");
                if(type && type.indexOf("application/json") !== -1){
                    const item = await item_data.json()
                    new_item.value.name = item[0].product;
                    if(item.length > 1){
                        await projectFirestore.collection('Apps').doc('Prices').collection('Users').doc(projectAuth.currentUser.uid).collection('Groupes')
                        .doc(new_item.value.group_id).collection('Items')
                        .doc(new_item.value.barcode+'_'+new_item.value.region).set(new_item.value)
                        clean_new_item();
                    }
                    else{
                        ElNotification({
                            title: "שגיאה!",
                            type: "error",
                            message: `פריט ${new_item.value.barcode} לא נמצא, ממשיך לפריטים הבאים`,
                            showClose: false
                        });
                    }
                }else{
                    clean_new_item();
                }
            }
        }
        importing_file.value = false;
        clean_new_item();
        ElNotification({
            title: "הצלחה",
            type: "success",
            message: `סיימנו לטעון את הקובץ. הפריטם יחלו להופיע...`,
            showClose: false
        });
    }

    onMounted(() => {
        loading.value = true;
        setTimeout(() => {
            get_groupes();
        }, 2000);
        setTimeout(() => {
            loading.value = false;
        }, 6000);
    })

    return{
        groupes, current_group, items, loading_items,
        add_item, add_item_dialog, new_item, regions, check_item,
        delete_item, update_group, update_group_dialog, index_to_edit,
        save_group, update_price, examine_item, examine_item_dialog, 
        selected_item, create_group, new_group, save_new_group,
        create_group_dialog, delete_group, select_group,
        clean_new_item, add_item_weighted_dialog,
        computed_width, loading_mini, check_list_dialog, loading,
        export_to_excel, check_catalog, import_items_from_excel, load_imported_xlsx,
        importing_file, importing_msgs, sorted_items, selected_competition,
        competition, competition_stores, selected_store, competition_price,
        store_price, store_sale, fetching_item, search_string, print_table_group
    }
}
}
</script>

<style scoped>
.prices{
    position: relative;
    width: calc(100%);
    height: 100%;
    overflow: hidden;
    display: grid;
    grid-template-areas:
    "toolbar toolbar toolbar" 
    "groups   main    main"
    "groups   main    main";
    grid-template-columns: calc(25% - 5px) calc(25% - 5px) calc(50% - 5px);
    grid-template-rows: calc(10% - 5px) calc(45% - 5px) calc(45% - 5px);
    gap: 5px;
    padding: 10px 75px 10px 65px;
}
.import-manager{
    position: absolute;
    width: 50%;
    height: 250px;
    background:var(--bg-bg);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
}
.loading{
    width: 80%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: whitesmoke;
}
.toolbar{
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    grid-area: toolbar;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5); 
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);   
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.1));
    padding: 5px;
}
.toolbar div:last-child{
    margin-left: 0;
    margin-right: auto;
    background-color: var(--bg-green);
    width: 150px;
}
.tool{
    width: calc(25% - 10px);
    height: 100%;
    background: linear-gradient(#00545db0, #000729ab);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: whitesmoke;
    font-size: 24px;
    border-radius: 3px;
    margin-left: 15px;

}
.tool:hover{
    background: var(--bg-bg)
}
.secondary{
    width: calc(20% - 10px);
    background-color: var(--bg-blue);
}
.groups{
    position: relative;
    grid-area: groups;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    overflow-y: auto;
    display: grid;
    grid-auto-rows: 80px;
    grid-auto-columns: 100%;
    gap: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5); 
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);   
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.1));
    padding: 5px;
}
.groups .title{
    width: 100%;
    height: 100%;
    background-color: var(--bg-green);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    color: whitesmoke;
}
.group{
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--bg-yellow);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.group-name{
    width: 60%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}
.group-actions{
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.main{
    position: relative;
    grid-area: main;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5); 
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);   
    background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.75));
    padding: 5px;
    overflow-y: auto;
}
.group-tools{
    width: 100%;
    height: 50px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-bottom: 5px;
}
#items {
  border-collapse: collapse;
  width: 100%;
}
#items td, #items th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
#items th{
    width: fit-content;
    position: sticky;
    top: 0; 
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.4);
    z-index: 1020;
}
#items tr{background-color: #f2f2f2;}
#items tr:hover {background-color: #ddd;}
#items th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04AA6D;
  color: white;
}
.loading-items{
    width: 100%;
    height: 100%;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
}
.add-item{
    width: 100%;
    height: 360px;
    display: flex;
    flex-direction: column;
}
.add-item-title{
    width: 100%;
    height: 10%;
    color: whitesmoke;
    background-color: var(--bg-green);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    margin-bottom: 10px;
}
.found-item{
    width: 100%;
    font-size: 22px;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
}
.my-price{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
.loading-mini{
    width: 100%;
    min-height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.export-excel{
    width: 80px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.export-excel img{
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
}
.fetching-group{
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
}
@media only screen and (max-width: 768px) {
    .prices{
        position: relative;
        width: 100%;
        height: 100%;
        grid-template-areas:
        "toolbar groups"
        "main    main";
        grid-template-columns: 50% 50%;
        grid-template-rows: 30% 70%;
        gap: 5px;
        overflow: hidden;
        padding: 70px 5px 10px 5px;
    }
    .toolbar{
        width: calc(100% - 5px);
        padding: 5px;
        display: grid;
        grid-auto-rows: 33%;
        grid-auto-columns: 100%;
        overflow-y: auto;
        gap: 5px;
    }
    .tool{
        width: 100%;
        height: 100%;
        margin: 0;
        overflow: hidden;
    }
    .toolbar div:last-child{
        margin-left: 0;
        margin-right: 0;
        background-color: var(--bg-green);
        width: 100%;
    }
    #items th{
        display: none;
    }
    #items td{
        display: flex;
        background-color: whitesmoke;
        align-items: center;
    }
    #items td:first-child{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    #items td:last-child{
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    #items td::before {
        content: attr(data-th) ": ";
        font-weight: bold;
        width: 25%;
        display: inline-block;
    }
    #items tr{
        padding: 2px;
        border-radius: 10px;
        overflow: hidden;
        background-color: unset;
    }
    #items {
        margin-top: 50px;
        border-collapse:separate;
        border-spacing:0 8px;    
        border-radius: 10px;
    }
    #items td:nth-child(7){
        display: none;
    }
    .group-tools{
        width: calc(100% - 10px);
        position: absolute;
        top: 0;
        overflow-x: auto;
        background-color: whitesmoke;
        justify-content: space-between;
    }
    .group-actions{
        justify-content: space-between;
    }
    .hide-mobile{
        display:  none;
    }
}

</style>