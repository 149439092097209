<template>
    <div class="check-list">
        <input type="file" id="file_input" v-show="false" @change="test_file">
        <div class="toolbar">
            <el-button type="success" class="toolbar-btn"> ייצא לאקסל</el-button>
            <el-button type="success" class="toolbar-btn"> שלח במייל</el-button>
            <el-button type="primary" class="toolbar-btn"> עזרה</el-button>
        </div>

        <div class="stats">
            <div class="info">
                <div class="step">
                    <p style="font-size: 24px; font-weight:bold; margin-left: 10px; color:var(--bg-green)">1</p>
                    <p style="font-weight:500;">הכן קובץ אקסל עם העמודות הבאות:</p>
                </div>
                <div class="step">
                    <p class="box-border">ברקוד</p>
                    <p class="box-border">מחיר עלות</p>
                    <p class="box-border">מחיר מכירה</p>
                </div>
                <div class="step" style="flex-direction:column;">
                    <p style="font-size:16px; padding: 5px;">המחירים הם אינם שדות חובה, עם זאת, כדאי להוסיף על מנת שהמערכת תבצע את החישובים בעצמה.</p>
                    <p style="font-weight: 500; color:var(--bg-red)"> כל המחירים כוללים מע"מ</p>
                </div>
                <el-divider/>
                <div class="step">
                    <p style="font-size: 24px; font-weight:bold; margin-left: 10px; color:var(--bg-yellow)">2</p>
                    <p style="font-weight:500;">לחץ על "טען קובץ אקסל"</p>
                </div>
                <div class="step" style="flex-direction: column;">
                    <p style="">בחר אזור להשוואה</p>
                    <el-select v-model="selected_region" placeholder="בחר אזור" style="width:90%; margin-top: 10px;">
                        <el-option v-for="region in regions" :key="region" :label="region" :value="region"/>
                    </el-select>
                    <el-button type="primary" @click="load_file" style="width:90%; margin-top: 10px;"> טען קובץ אקסל</el-button>
                </div>
            </div>
            <div class="filters">
                <div class="filters-title">
                    <p style="font-weight:400;">מסננים</p>
                </div>
                <div class="filter">
                    <el-select v-model="selected_competition" placeholder="מתחרה עיקרי" clearable class="select">
                        <el-option v-for="(comp) in competition" :key="comp.store" :label="comp.store" :value="comp.store"/>
                    </el-select>
                </div>
                <div class="filter" v-if="selected_competition_stores">
                    <el-select v-model="selected_adress" placeholder="כתובת מסויימת" clearable class="select">
                        <el-option v-for="(item) in selected_competition_stores" :key="item" :label="item" :value="item" />
                    </el-select>
                </div>
            </div>
        </div>

        <div class="results">
            <div class="waiting-for-file" v-if="state == 'init'">
                <img src="@/assets/waiting.svg" style="width: 400px; height:auto; margin-bottom: 25px;">
                <p>ממתין לקובץ...</p>
            </div>

            <div class="checking-file" v-if="state == 'testing-file'">
                <img src="https://i.pinimg.com/originals/65/ba/48/65ba488626025cff82f091336fbf94bb.gif" style="width: 400px; height:auto; margin-bottom: 25px;">
                <p>בודק את תקינות הקובץ, אנא המתן...</p>
            </div>

            <div class="checking-file" v-if="state == 'proc-file'">
                <img src="https://i.pinimg.com/originals/65/ba/48/65ba488626025cff82f091336fbf94bb.gif" style="width: 400px; height:auto; margin-bottom: 25px;">
                <p>מעבד את הקובץ, אנא המתן...</p>
            </div>
            
            <div class="data-table" id="data-table" v-if="state == 'build-table'">
                <table id="items">
                    <tr>
                        <th>סטטוס</th>
                        <th>ברקוד</th>
                        <th>שם המוצר</th>
                        <th>מחיר עלות</th>
                        <th>מחיר מכירה</th>
                        <th>מס' חנויות</th>
                        <th>יקר (קטלוגי)</th>
                        <th>זול (קטלוגי)</th>
                        <th v-if="selected_competition">{{selected_competition}}</th>
                        <th v-if="selected_competition && selected_adress">{{selected_adress.split(' ')[0] +' '+ selected_adress.split(' ')[1]}}</th>
                        <th>פירוט</th>
                    </tr>
                    <template v-for="item in table_data" :key="item.barcode">
                        <tr>
                            <td>
                                <p style="background-color:var(--bg-yellow);" v-if="item.status == 'טוען'">{{item.status}}</p>
                                <p style="background-color:var(--bg-green); color: white;" v-if="item.status == 'טופל'">{{item.status}}</p>
                                <p style="background-color:var(--bg-red); color:white;" v-if="item.status == 'אין תוצאות'"> {{item.status}}</p>
                            </td>
                            <td>{{item.barcode}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.cost}}</td>
                            <td>{{item.sell}}</td>
                            <td>{{item.prices.length}}</td>
                            <td>{{item.heigh}}</td>
                            <td>{{item.low}}</td>

                            <td class="comp" v-if="selected_competition && get_comp_price(item) != 0">{{get_comp_price(item)}}</td>
                            <td class="comp not-selling" v-if="selected_competition && get_comp_price(item) == 0">לא מוכר</td>

                            <td class="comp-adress" v-if="selected_competition && selected_adress && get_adress_price(item) != 0">{{get_adress_price(item)}}</td>
                            <td class="comp not-selling" v-if="selected_competition && selected_adress && get_adress_price(item) == 0">לא מוכר</td>

                            <td><el-button type="primary" icon="el-icon-document" @click="examine_item(item)" circle /></td>
                        </tr>
                    </template>
                </table>
            </div>
            <div class="examine-tools" v-if="examine && item_examine">
                <div class="examine-btn" @click="close_examine">
                    סגור
                </div>
                <div class="product-name">
                    {{item_examine.name}}
                </div>
            </div>
            <div class="examine" v-if="examine && item_examine">
                <table id="items">
                    <tr>
                        <th>רשת</th>
                        <th>כתובת</th>
                        <th>מחיר</th>
                        <th>מבצע</th>
                        <th>תוקף</th>
                        <th>השוואה</th>
                    </tr>
                    <template v-for="(seller,i) in item_examine.prices" :key="i">
                        <tr>
                            <td data-th="רשת" :class="check_class_examine(seller)">{{seller.name}}</td>
                            <td data-th="כתובת" :class="check_class_examine_adress(seller)">{{seller.adress.replace(item_examine.region, '').replace(',', '').replace(',', '')}}</td>
                            <td data-th="מחיר">{{seller.price}}</td>
                            <td data-th="מבצע">
                                {{seller.sale.price.split('(')[0].replace('ש"ח', '')}}
                            </td>
                            <td data-th="תוקף">{{seller.sale.to_date}}</td>
                            <td v-if="item_examine.sell" data-th="השוואה">
                                <template v-if="(((Number(seller.price) / item_examine.sell)-1) * 100).toFixed(1) < 0">
                                    <p style="color:var(--bg-red);">{{(((Number(seller.price) / item_examine.sell)-1) * -100).toFixed(0)}}%-</p>
                                </template>
                                <template v-else>
                                    <p style="color:green;">{{(((Number(seller.price) / item_examine.sell)-1) * 100).toFixed(0)}}%+</p>
                                </template>
                            </td>
                            <td data-th="השוואה" v-else>
                                חסר נתון
                            </td>
                        </tr>
                    </template>
                </table>
            </div>

        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import Swal from 'sweetalert2';
import { ElNotification } from "element-plus";
import XLSX from "xlsx";
import { computed, watch } from '@vue/runtime-core';

export default {
setup(){
    const state = ref('init');
    const table_data = ref([]);
    const selected_region = ref('')
    const examine = ref(false);
    const item_examine = ref(null);
    const competition = ref([]);
    const selected_competition = ref(null);
    const selected_adress = ref(null);
    const regions = [
        "תל אביב",
        "חיפה",
        "נתניה",
        "אשקלון",
        "רעננה",
        "באר שבע",
        "אילת"
    ]
    
    const load_file = () => {
        state.value = 'init';
        table_data.value = [];
        competition.value = [];
        examine.value = false;
        item_examine.value = null;
        selected_competition.value = null;
        selected_adress.value = null;

        const btn = document.getElementById('file_input')
        if(btn){
            btn.click();
        }else{
            Swal.fire({
                icon: 'error',
                title: 'שגיאת מערכת',
                text: 'צור קשר עם תמכיה טכנית.'
            });
        }
    }

    const test_file = (e) =>{
        state.value = 'testing-file'
        var file = e.target.files[0];
        if(!file){
            state.value = 'init';
            return
        }
        if(!file.name.includes('xlsx')){
            ElNotification({
                title: "שגיאה!",
                type: "error",
                message: "ניתן לטעון קובץ אקסל בלבד!",
                showClose: false
            });
            state.value = 'init';
            return
        }
        setTimeout(() => {
            state.value = 'proc-file';
            proccess_xml(file);
        }, 1500);
    }

    const proccess_xml = (file) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = e => {
                const bufferArray = e.target.result;
                const workbook = XLSX.read(bufferArray, {
                    type: "buffer",
                    cellDates: true,
                    dateNF: "yyyy/mm/dd;@"
                });
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];
                const data = XLSX.utils.sheet_to_json(worksheet);
                resolve(data);
            };
            fileReader.onerror = error => {
            reject(error);
            };
        })
        promise.then(data => {
            check_data(data);
        })
    }

    const check_data = (data) => {
        for(let i = 0; i< data.length; i++){
            let item = data[i];
            if(!item['ברקוד']){
                ElNotification({
                    title: "שגיאה!",
                    type: "error",
                    message: "בדוק את שדות קובץ האקסל, חסרה עמודת 'ברקוד' !!",
                    showClose: false
                });
                state.value = 'init';
                return
            }
        }
        console.log('File is - OK!');
        setTimeout(() => {
            build_table(data);
        }, 1500);
    }

    const build_table = (data) =>{
        for(let i = 0; i< data.length; i++){
            let item = data[i];
            let barcode = item['ברקוד'];
            let cost = Number(item['מחיר עלות']).toFixed(2);
            let sell = Number(item['מחיר מכירה']).toFixed(2);

            if(isNaN(cost)){ cost = ''};
            if(isNaN(sell)){sell = ''};

            table_data.value.push({
                barcode: barcode,
                cost: cost,
                sell: sell,
                name: 'טוען',
                status: 'טוען',
                prices: [],
                region: selected_region.value
            })
        }
        console.log(table_data.value);
        state.value = 'build-table';
        get_prices();
    }

    const get_prices = async () => {
        for(let i = 0; i< table_data.value.length; i++){
            var item = table_data.value[i];
            const item_data = await fetch(`https://cyberpark-server.herokuapp.com/admin/fetchpricelist/${item.barcode}/${item.region}`);
            const type = item_data.headers.get("content-type");
            if(type && type.indexOf("application/json") !== -1){
                let prices = await item_data.json();
                prices.sort((itA, itB) => {
                    if(Number(itA.price) < Number(itB.price)){return -1}
                    if(Number(itA.price) > Number(itB.price)){return 1}
                    return 0
                })

                prices.forEach(store => {
                    let i = competition.value.findIndex(sto => {
                        return sto.store == store.name
                    })
                    if(i == -1){
                        competition.value.push({
                            store: store.name,
                            branches: [store.adress]
                        });
                    }else{
                        if(!competition.value[i].branches.includes(store.adress)){
                            competition.value[i].branches.push(store.adress)
                        }
                    }
                });

                if(prices.length > 1){
                    item.name = prices[0].product;
                    item.prices = prices;
                    item.status = 'טופל';
                    item.low = prices[0].price;
                    item.heigh = prices[item.prices.length - 1].price;
                }else{
                    item.status = "אין תוצאות";
                    item.name = "אין תוצאות";
                }

            }else{
                item.status = "אין תוצאות"
                item.name = "אין תוצאות";
            }
        }
        console.log('Done gathering price data!');
        ElNotification({
            title: "הצלחה!",
            type: "success",
            message: "סיימנו לבדוק את כל הרשימה.",
            showClose: false
        });
    } 

    const examine_item = (item) =>{
        const data_table = document.getElementById('data-table');
        if(data_table){
            data_table.style.height = '60%'
            examine.value = true;
            item_examine.value = item;
        }
    }

    const close_examine = () =>{
        const data_table = document.getElementById('data-table');
        if(data_table){
            data_table.style.height = '100%'
            examine.value = false;
            item_examine.value = null;
        }
    }

    const check_class_examine = (seller) => {
        if(seller.name == selected_competition.value){
            return 'comp'
        }
    }

    const check_class_examine_adress = (seller) => {
        if(seller.adress == selected_adress.value){
            return 'comp-adress'
        }
    }
    
    const selected_competition_stores = ref(computed(()=> {
        let arr = [];
        if(selected_competition.value){
            table_data.value.forEach(item => {
                if(item.prices && item.prices.length > 0){
                    item.prices.forEach(store => {
                        if(store.name == selected_competition.value){
                            if(!arr.includes(store.adress)){
                                arr.push(store.adress)
                            }
                        }
                    })
                }
            })
        }
        return arr
    }))

    const get_comp_price = (item) => {
        let min = 10000;
        if(item.prices && selected_competition.value){
            item.prices.forEach(store => {
                if(store.name == selected_competition.value){
                    if(Number(store.price) < min){
                        min = Number(store.price)
                    }
                }
            })
        }
        if(min == 10000){min = 0}
        return min
    }

    const get_adress_price = (item) => {
        let price = 0;
        if(item.prices && selected_competition.value){
            if(selected_adress.value){
                item.prices.forEach(store => {
                    if(selected_competition.value == store.name){
                        if(store.adress == selected_adress.value){
                            price =  Number(store.price)
                        }
                    }
                })
            }
        }
        return price
    }

    watch(selected_competition, () => {
        selected_adress.value = null;
    })
    
    return{
        state, load_file, test_file, table_data,
        examine_item, examine, item_examine, close_examine,
        competition, selected_competition, get_comp_price,
        selected_adress, get_adress_price, selected_competition_stores,
        check_class_examine, check_class_examine_adress,
        selected_region, regions
    }
}
}
</script>

<style scoped>
.check-list{
    position: relative;
    width: 100%;
    height: calc(100vh - 90px);
    overflow: hidden;
    display: grid;
    grid-template-areas:
    "toolbar  results" 
    "stats    results";
    grid-template-columns: calc(25% - 5px) calc(75% - 5px);
    grid-template-rows: calc(10% - 5px) calc(90% - 5px);
    gap: 5px;
}
.toolbar{
    grid-area: toolbar;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: 10px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}
.toolbar-btn{
    height: 100%;
    margin-left: 15px;
    width: 33%;
}
.stats{
    grid-area: stats;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
}
.info{
    width: 100%;
    height: 55%;
    background-color: rgb(238, 236, 236);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.step{
    width: 100%;
    margin-top: 10px;
    text-align: center;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.box-border{
    border: 1px solid black;
    padding: 2px;
}
.filters{
    margin-top: 10px;
    width: 100%;
    height: calc(45% - 10px);
    background-color: rgb(238, 236, 236);
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.filters-title{
    width: 100%;
    height: 50px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: whitesmoke;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.filter{
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    justify-content: space-evenly;
    margin: 5px 0 5px 0;
}
.results{
    grid-area: results;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.waiting-for-file{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    text-align: center;
    flex-direction: column;
}
.checking-file{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    text-align: center;
    flex-direction: column;
}
.data-table{
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.examine{
    position: relative;
    width: 100%;
    height: calc(40% - 30px);
    overflow-y: auto;
}
.examine-tools{
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.examine-btn{
    height: 100%;
    width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-blue);
    cursor: pointer;
    color: white;
}
#items {
  border-collapse: collapse;
  width: 100%;
}
#items td, #items th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}
#items th{
    width: fit-content;
    position: sticky;
    top: 0; 
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.4);
    z-index: 1020;
}
#items tr{background-color: #f2f2f2;}
#items tr:hover {background-color: #ddd;}
#items th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #04AA6D;
  color: white;
}

.select{
    width: 95%;
}
.filter-btn{
    width: 24%;
    height: 100%;
}
.comp{
    background-color: yellow;
}
.comp-adress{
    background-color: rgb(250, 221, 249);
}
.not-selling{
    background-color: rgb(218, 143, 4);
}
.product-name{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    background-color: rgb(167, 243, 239);
    padding: 0 5px 0 5px;
    font-weight: 500;
}
</style>