<template>
    <div class="preset-items">
        <div class="my-msg">
            <p>חסר פריט? שלחו לנו באמצעות הצ'ט!!</p>
        </div>
        <div class="group-title">
            {{group.name}}
        </div>
        <div class="search-bar">
            <el-select v-model="selected_department" placeholder="מחלקה" clearable class="select">
                <el-option v-for="item in departments" :key="item" :label="item" :value="item" />
            </el-select>
            <el-select v-model="selected_region" placeholder="אזור" clearable class="select">
                <el-option v-for="item in regions" :key="item" :label="item" :value="item" />
            </el-select>
            <el-input placeholder="חיפוש" v-model="search_string" class="select" />
        </div>
        <div class="items-grid">
            <template v-for="item in filtered_items" :key="item.name">
                <div class="item-box" @click="select_item(item)">
                    <div class="item-image">
                        <img :src="item.image" alt="">
                    </div>
                    <div class="item-name">
                        <p>{{item.name}}</p>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { computed, watch } from '@vue/runtime-core'
import { ElNotification } from "element-plus";
import { projectAuth, projectFirestore } from '../../firebase/config';

export default {
props:['group', 'groupitems'],
setup(props){
    const items = ref([
        {
            name: 'מלפפונים',
            image: 'https://image.flaticon.com/icons/png/512/2346/2346905.png',
            url: 'מלפפונים, מחיר לפי משקל',
            department: 'ירקות'
        },
        {
            name: 'עגבניות',
            image: 'https://image.flaticon.com/icons/png/512/4264/4264743.png',
            url: 'עגבניות, מחיר לפי משקל',
            department: 'ירקות'
        },
        {
            name: 'בצל יבש',
            image: 'https://image.flaticon.com/icons/png/512/862/862847.png',
            url: 'בצל יבש, מחיר לפי משקל',
            department: 'ירקות'
        },
        {
            name: 'בצל אדום',
            image: 'https://image.flaticon.com/icons/png/512/2503/2503897.png',
            url: 'בצל אדום, מחיר לפי משקל',
            department: 'ירקות'
        },
        {
            name: 'בטטה',
            image: 'https://image.flaticon.com/icons/png/512/4278/4278227.png',
            url: 'בטטה, מחיר לפי משקל',
            department: 'ירקות'
        },
        {
            name: 'גזר ארוז',
            image: 'https://image.flaticon.com/icons/png/512/1041/1041355.png',
            url: 'גזר ארוז, מחיר לפי משקל',
            department: 'ירקות'
        },
        {
            name: 'תפוח אדמה אדום ארוז',
            image: 'https://image.flaticon.com/icons/png/512/4056/4056844.png',
            url: 'תפוח אדמה אדום ארוז, מחיר לפי משקל',
            department: 'ירקות'
        },
        {
            name: 'לימון',
            image: 'https://image.flaticon.com/icons/png/512/590/590768.png',
            url: 'לימון, מחיר לפי משקל',
            department: 'פירות'
        },
        {
            name: 'בננות',
            image: 'https://image.flaticon.com/icons/png/512/3143/3143645.png',
            url: 'בננות, מחיר לפי משקל',
            department: 'פירות'
        },
        {
            name: 'מלון',
            image: 'https://image.flaticon.com/icons/png/512/1515/1515045.png',
            url: 'מלון, מחיר לפי משקל',
            department: 'פירות'
        },
        {
            name: 'תפוח עץ סמיט',
            image: 'https://image.flaticon.com/icons/png/512/1041/1041134.png',
            url: 'תפוח עץ סמיט, מחיר לפי משקל',
            department: 'פירות'
        },
        {
            name: 'חצילים',
            image: 'https://image.flaticon.com/icons/png/512/590/590780.png',
            url: 'חצילים, מחיר לפי משקל',
            department: 'פירות'
        },
        {
            name: 'נקטרינה',
            image: 'https://image.flaticon.com/icons/png/512/1614/1614281.png',
            url: 'נקטרינה, מחיר לפי משקל',
            department: 'פירות'
        },
        {
            name: 'פלפל אדום',
            image: 'https://image.flaticon.com/icons/png/512/836/836590.png',
            url: 'פלפל אדום, מחיר לפי משקל',
            department: 'פירות'
        },
    ])
    const departments = ref([
        "ירקות",
        "פירות",
        "בשר טרי",
        "גבינות",
        "נקניקים"
    ])
    const regions = [
        "תל אביב",
        "חיפה",
        "נתניה",
        "אשקלון",
        "רעננה",
        "באר שבע",
        "אילת"
    ]
    const selected_department = ref('');
    const selected_region = ref('');

    const search_string = ref('');
    
    const filtered_items = ref(computed(() => {
        if(!search_string.value){
            if(selected_department.value == ''){
                return items.value
            }else{
                return items.value.filter(item => {
                    return item.department == selected_department.value
                })
            }
        }else{
            return items.value.filter(item => {
                return item.name.includes(search_string.value)
            })
        }

    }))

    const select_item = async (item) => {
        if(!selected_region.value){
            ElNotification({
                title: "שגיאה!",
                type: "error",
                message: "בחר אזור להשוואה בבקשה!",
                showClose: false
            });
            return
        }
        let i = props.groupitems.findIndex(itm => {
            return itm.name == item.name
        })
        if(i != -1){
            //product already exists!
            ElNotification({
                title: "שגיאה",
                type: "error",
                message: "המוצר כבר קיים באחת הקבוצות.",
                showClose: false
            });
        }else{
            const new_item = {
                name: item.name,
                group_id: props.group.id,
                barcode: item.url,
                region: selected_region.value,
                my_price: ''
            }
            await projectFirestore.collection('Apps').doc('Prices').collection('Users').doc(projectAuth.currentUser.uid).collection('Groupes').doc(new_item.group_id).collection('Items')
            .doc(new_item.barcode+'_'+new_item.region).set(new_item)
            ElNotification({
                title: "הצלחה!",
                type: "success",
                message: "המוצר התווסף בהצלחה!",
                showClose: false
            });
        }
    }

    watch(selected_department, () => {
        search_string.value = '';
    })

    return{
        items, departments, selected_department,
        filtered_items, select_item, selected_region,
        regions, search_string
    }
}
}
</script>

<style scoped>
.my-msg{
    position: absolute;
    top: 15px;
    left: 15px;
    background-color: var(--bg-blue);
    color:whitesmoke;
    padding: 5px;
    border-radius: 3px;
}
.preset-items{
    width: 100%;
    height: 70vh;
    display: grid;
    grid-template-areas:
    "search-bar group-title"
    "items-grid items-grid";
    grid-template-rows: 60px calc(100% - 60px);
    grid-template-columns: 75% 25%;
}
.group-title{
    grid-area: group-title;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bg-yellow);
    font-size: 26px;
}
.items-grid{
    grid-area: items-grid;
    width: 100%;
    height: calc(100% - 10px);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 20%;
    gap: 5px;
    overflow-y: auto;
    margin-top: 10px;
}
.item-box{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: whitesmoke;
    border-radius: 6px;
    cursor: pointer;
}
.item-box:hover{
    background-color: rgb(204, 203, 203);
}
.item-image{
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item-image img{
    max-width: 100%;
    max-height: 100%;
}
.item-name{
    width: 100%;
    height: 15%;
    font-size: 26px;
    text-align: center;
}
.search-bar{
    grid-area: search-bar;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    display: flex;
    align-items: center;
    background-color: var(--bg-green);
    color: whitesmoke;
    padding: 5px;
    justify-content: space-evenly;
}
.select{
    width: 30%;
}
@media only screen and (max-width: 768px) {
    .preset-items{
        height: 64vh;
        grid-template-areas:
        "group-title"
        "search-bar"
        "items-grid";
        grid-template-rows: 60px 60px calc(100% - 120px);
        grid-template-columns: 100%;
    }
    .item-name{
        width: 100%;
        height: 15%;
        font-size: 20px;
        text-align: center;
    }
    .items-grid{
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: 33%;
    }
    .select{
        width: calc(33% - 5px);
    }
}
</style>